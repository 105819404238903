import React, { useState, useEffect, lazy } from "react";
import axios from "axios";
import { Slider, Statistic, Tabs,Result, Modal, List, Affix, Switch } from "antd";
import {
  ArrowUpOutlined,
  MinusCircleFilled,
  ToolOutlined,
  ArrowDownOutlined,
  FileFilled,
  WalletFilled,
  SearchOutlined,
  FundViewOutlined,
  UserOutlined,
  AccountBookOutlined,
  CloseOutlined,
  CalculatorFilled,
  SwapOutlined,
  GroupOutlined,
  AccountBookFilled,
  DollarCircleOutlined,
  EditFilled,
  MessageFilled,
  DollarOutlined,
  CarFilled,
  LoadingOutlined,
  PlusCircleFilled,
  CheckOutlined,
  RedoOutlined
} from "@ant-design/icons";
import moment from "moment";
import { Line, Pie, Bar } from "react-chartjs-2";
import { withGetScreen } from "react-getscreen";
import {
  Tooltip,
  Popover,
  Table,
  Popconfirm,
  Input,
  Image,
  Button,
  Collapse,
  Card,
  Avatar,
  Form,
  DatePicker,
  Select,
  Space,
  Typography,
  Divider,
  InputNumber,
  message,
  Spin,
} from "antd";
import { Col, Row } from "reactstrap";
import { CustomTooltips } from "@coreui/coreui-plugin-chartjs-custom-tooltips";
import { getStyle, hexToRgba } from "@coreui/coreui/dist/js/coreui-utilities";
import * as serverconfig from "./serverconn";
import {
  MdAdd,
  MdEmail,
  MdHome,
  MdMailOutline,
  MdPhone,
  MdShoppingCart,
} from "react-icons/md";

import CurrencyFormat from "react-currency-format";
import Highlighter from "react-highlight-words";
import Websocket from "react-websocket";
import { Link } from "react-router-dom";
import ReconnectingWebSocket from "reconnecting-websocket";
import { NavLink } from "react-router-dom";
import * as primarycolor from "./primarycolor";
import logo from "../assets/food_placeholder.png";
import * as reactstrp from "reactstrap";
import './menu.css'
import MyContext from './MyContext';
import { number } from "prop-types";
import './SlideBar.css'; // Import CSS file for styling
import SignUpForm from './SignUpForm';


var CryptoJS = require("crypto-js");

const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

const style = {
  background: "#0092ff",
  padding: "8px 0",
  borderRadius: "7px",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
};
const dateFormat = "DD/MM/YYYY";
const yeardateFormat = "YYYY";
const monthdateFormat = "MM/YYYY";

const { Meta } = Card;
const { Panel } = Collapse;
const { TabPane } = Tabs;
const FormItem = Form.Item;
const { Option } = Select;
const { Search } = Input;
const t_time="HH:mm:ss"


var bizuserid = "";
var sacco = "";
var token = "";

class RestaurantsMenuDetails extends React.Component {
  state = {
    datarequested: true,
    contine_withgoogle: false,
    menu_obj: {},
    menu_dishes: [],
    menu_dishes_placeholders: [],

    selected_dish: {},
    selected_dish_visible: false,

    current_cart_list: [],
    quantity: 0,
    extra_list: [],
    choice_list:[],
    restaurant_name: this.props.restaurant_name,

    selected_xtra_list: [],
    selected_choice_list: [],


    //now proceed to upload section
    mode_of_payment:'Cash',
    additional_addressinfo:'',
    notes:'',
    areas:[],
    area:'',
    delivery_price:0,
    hub_zones:[],
    telephone_contact:'',
    datasubmittedsuccessfully:false,

    view_basket:false,
    restaurant_obj:this.props.restaurant_obj,

    read_more:false,
    dish_id_toread_more:'',
    status_message:"",

    edit_model_visible:false,
    editable_dish:{}

  };


  callback = (key) => {
    console.log(key);
    console.log(this.state.yearlymonthlysales);
  };

  componentDidMount() {
    this.comp_loaded()
  }



   //check if area already added
   isareaAlreadyExists = (area, list) => {
    return list.some((item) => item === area);
  };



 comp_loaded=()=>{
  const { setshowtop_items } = this.context;
  // Update the context value
  setshowtop_items(false);


  var menu_object = this.props.menu_object;
  var res_data=this.props.restaurant_obj

  this.setState({ menu_object: menu_object });

  //gettting menu dishes
  let form_data = new FormData();
  form_data.append("backend", serverconfig.backendserverurl);
  form_data.append("menu_id", menu_object.id);
  axios
    .post(
      serverconfig.backendserverurl + `/customqueries/get_menu_dishes`,
      form_data,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    )
    .then((res) => {
      this.setState({
        menu_dishes: JSON.parse(res.data.report),
      });

      this.setState({
        menu_dishes_placeholders: JSON.parse(res.data.report),
      });

      this.setState({ datarequested: false });
    });



  //get the current cut list
  let value = localStorage.getItem("current_cart_list");
  if (value != null) {
    if (this.state.restaurant_name === JSON.parse(value).restaurant_name) {
      this.setState({
        current_cart_list: JSON.parse(JSON.parse(value).items),
      });
    } else {
      this.setState({ current_cart_list: [] });
    }
  } else {
    this.setState({ current_cart_list: [] });
  }


  //get areas
  let form_data_1_1 = new FormData();
  axios
   .post(
     serverconfig.backendserverurl + `/customqueries/get_all_areas`,
     form_data_1_1,
     {
       headers: {
         "content-type": "multipart/form-data",
       },
     }
   )
   .then((res) => {
     this.setState({
       areas: JSON.parse(res.data.report),
     });
   });

 
   
  //get hub zones
  let form_data_o1 = new FormData();
  form_data_o1.append("restaurant_id", res_data.id);
    axios.post(serverconfig.backendserverurl+'/customqueries/get_restaurant_hubzones',
    form_data_o1, {
    headers: {
      "content-type": "multipart/form-data",
    },
  })

  .then(res => {
    this.setState({
      hub_zones: JSON.parse(res.data.report),
    });

    })
  .catch(error => console.log(error))

   this.setState({datasubmittedsuccessfully:false})

  }

  //calculate price

  //Supply exists
  isiteminbasket = (item_name, basketlist) => {
    return basketlist.some((item) => item.item === item_name);
  };

  checKIfItem_inaddons = (item_name, list) => {
    return list.some((item) => item.item === item_name);
  };

  //check if item in extralist
  checKIfItem_inextralist = (item_name, list) => {
    return list.some((item) => item.item === item_name);
  };


  checKIfItem_inchoicelist = (item_name, list) => {
    return list.some((item) => item.item === item_name);
  };


  //calculate totla
  calculateTotal = () => {
    var total = 0;
    this.state.current_cart_list.map((item) => {
      total += Number(item.total);
    });

    return total;
  };

  //calcute extras total
  calculate_xtrasTotal = () => {
    var extrs_totalA = 0;
    this.state.selected_xtra_list.map((ex_t) => {
      extrs_totalA += Number(ex_t.price);
    });

    return extrs_totalA;
  };


  //get first fifty words

   getWordsInFirst50Characters=(text)=> {
    // Split the text into words
    const words = text.split(' ');
  
    // Initialize variables to keep track of total characters and words
    let characterCount = 0;
    let wordCount = 0;
    const result = [];
  
    // Iterate through words until the character count reaches 50
    for (const word of words) {
      // Calculate the new character count if we include the next word
      const newCharacterCount = characterCount + word.length;
  
      // If adding the next word exceeds 50 characters, break the loop
      if (newCharacterCount > 30) {
        break;
      }
  
      // Add the word to the result array
      result.push(word);
  
      // Update character count and word count
      characterCount = newCharacterCount;
      wordCount++;
    }
  
    // Join the words in the result array to form the truncated text
    const truncatedText = result.join(' ');
  
    return truncatedText;
  }





  //capitalize fistrt letter
   capitalizeFirstLetter=(word)=> {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }


  capitalizeSentence=(sentence)=> {
    return sentence.split(' ').map(word =>
      word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' ');
  }
  
  


  render() {
    if (this.state.datarequested === true) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large">
            <div className="content">
              <h3>eats.biz</h3>
            </div>
          </Spin>
        </div>
      );
    } else {


      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>
              <Card>
              <Result
              status="success"
              title={this.state.status_message}
              extra={[
                <Button  type='primary' 
                onClick={()=>{
                  //this.comp_loaded()
                  window.location.reload(false)

                }}>Finish</Button>,
              ]}
              />

             </Card>
          </div>
        )    

      }else{

        return (
          <div>
            <p
              style={{
                display: "flex",
                justifyContent: "flex-start",
                fontSize: 30,
              }}
            >
              {this.state.menu_object.description}
            </p>


            <FormItem label="Search">
              <Search
              placeholder="Search"
              onChange={(value) => {
                var val=value.target.value
                console.log(val)
                if (val==='' || val===undefined || val===null){
                  this.setState({menu_dishes:this.state.menu_dishes_placeholders});
                }

                if (val !== '' || val !== undefined) {
                  //set one is by NAME
                  const results_1 = this.state.menu_dishes.filter((request) => {
                    return String(request.name).toLowerCase().includes(val.toLowerCase());
                    // Use the toLowerCase() method to make it case-insensitive
                  });
            
                  if (results_1.length === 0) {
                    this.setState({menu_dishes:this.state.menu_dishes_placeholders});

                  } else {
                    this.setState({menu_dishes:results_1});
                    console.log(results_1)
                  }
            
                } else {
                  this.setState({menu_dishes:this.state.menu_dishes_placeholders});
                }
            
              }}
              style={{
                width: 200,
                margin:5
              }}
              suffix={<RedoOutlined onClick={()=>{
                this.setState({menu_dishes:this.state.menu_dishes_placeholders});

              }} style={{ cursor: 'pointer' }} />}

              />
          </FormItem>


            {this.state.menu_dishes.length > 0 ? (
              <div>
                {this.state.menu_dishes.map((item) => {
                  return (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          margin:10
                        }}
                      >

                        {/**New Image and buttons */}
                      <span style={{display:'flex',
                        height:250,
                        width: '40%',
                        backgroundColor:'white',
                        alignItems:'center',
                        justifyContent:'center',
                        borderRadius: '5px',
                        flexDirection:'column',
                        margin:4

                        }}>

                     <span 
                      style={{
                        height:200,
                        width: 200,

                        display:'flex',
                        flexDirection:'column',
                        backgroundImage: `url(${item.image === "" ? logo:item.image})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        //height: '300px', // Adjust height as needed
                        justifyContent:'center',
                        alignItems:'center',
                        borderRadius: '5px',
                        alignSelf:'flex-end'

                        }}>

                        </span>
                        <br></br>


                          {/* start of add buttons*/}

                          {this.isiteminbasket(item.name,this.state.current_cart_list) === true ? (
                          <div
                       
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            background: 'white',
                           // padding: 8,
                            height:30,
                            alignItems:'center',
                            border: '1px solid lightgreen',
                            width:100,
                            alignSelf:'flex-end'
                          }}
                          >
                          <span 
                          className="no-select"
                          style={{ color:primarycolor.primarycolor,margin:4,fontSize: 18 }}
                          
                          onClick={() => {
                            const elementsIndex =
                              this.state.current_cart_list.findIndex(
                                (element) => element.item == item.name
                              );
                            let newArray = [...this.state.current_cart_list];
                            console.log(elementsIndex);
        
                            var pre_bj = newArray[elementsIndex];
                            var pre_qty = Number(pre_bj.quantity);
                            var pre_price = Number(pre_bj.price);
                            var pre_key = pre_bj.key;
        
                            newArray[elementsIndex] = {
                              ...newArray[elementsIndex],
                              quantity: pre_qty + 1,
                              total: (pre_qty + 1) * pre_price,
                            };
        
                            this.setState({
                              current_cart_list: newArray,
                            });
        
                            //update local storage
                            localStorage.removeItem("current_cart_list");
                            localStorage.setItem(
                              "current_cart_list",
                              JSON.stringify({
                                items: JSON.stringify(newArray),
                                restaurant_name: this.state.restaurant_name,
                              })
                            );
                          }}
                          
                          >
                            +
                          </span>

                          <span style={{ backgroundColor: primarycolor.primarycolor, fontSize: 18,margin:4 }}>
                           <span style={{color:'white',margin:4,height:'100%'}}>
                           {this.state.current_cart_list.map((itn) => {
                              if (itn.item === item.name) {
                                return itn.quantity;
                              }
                            })}
                            
                            </span> 
                          </span>


                          <span 
                          className="no-select"
                          style={{ color:primarycolor.primarycolor, fontSize: 18,margin:4 }}
                          onClick={() => {
                            const elementsIndex =
                              this.state.current_cart_list.findIndex(
                                (element) => element.item == item.name
                              );
                            let newArray = [...this.state.current_cart_list];
      
                            var pre_bj = newArray[elementsIndex];
                            var pre_qty = Number(pre_bj.quantity);
                            var pre_price = Number(pre_bj.price);
                            var pre_key = pre_bj.key;
      
                            if (pre_qty <= 1) {
                              this.setState({
                                current_cart_list: [
                                  ...this.state.current_cart_list.filter(
                                    (todo) => todo.key !== pre_key
                                  ),
                                ],

                              });

                              let newArray = 
                              [
                                ...this.state.current_cart_list.filter(
                                  (todo) => todo.key !== pre_key
                                ),
                              ]
                              ;

                              //update local storage
                              localStorage.removeItem("current_cart_list");
                              localStorage.setItem(
                                "current_cart_list",
                                JSON.stringify({
                                  items: JSON.stringify(newArray),
                                })
                              );



                            } else {
                              newArray[elementsIndex] = {
                                ...newArray[elementsIndex],
                                quantity: pre_qty - 1,
                                total: (pre_qty - 1) * pre_price,
                              };
      
                              this.setState({
                                current_cart_list: newArray,
                              });
      
                              //update local storage
                              localStorage.removeItem("current_cart_list");
                              localStorage.setItem(
                                "current_cart_list",
                                JSON.stringify({
                                  items: JSON.stringify(newArray),
                                  restaurant_name: this.state.restaurant_name,
                                })
                              );
                            }
                          }}
      
                          >
                            -
                          </span>

                          </div>


                        ) : (
                          <div
                            style={{
                              display: "flex",
                              fontWeight: "bolder",
                              //fontSize: 18,
                              //margin: 4,
                              //justifyContent:'flex-start'
                              alignSelf:'flex-end'

                            }}
                          >
                            <Button
                              onClick={() => {
                                this.setState({ selected_dish: item });
                                this.setState({ selected_dish_visible: true });
  
                                //get dish extras
  
                                let form_data = new FormData();
                                form_data.append("dish_id", item.id);
                                axios
                                  .post(
                                    serverconfig.backendserverurl +
                                      `/customqueries/get_dish_extras`,
                                    form_data,
                                    {
                                      headers: {
                                        "content-type": "multipart/form-data",
                                      },
                                    }
                                  )
                                  .then((res) => {
                                    this.setState({
                                      extra_list: JSON.parse(res.data.report),
                                    });
                                    console.log(JSON.parse(res.data.report));
                                  });


                                  let form_data_1 = new FormData();
                                  form_data_1.append("dish_id", item.id);
                                  axios
                                    .post(
                                      serverconfig.backendserverurl +
                                        `/customqueries/get_dish_choices`,
                                        form_data_1,
                                      {
                                        headers: {
                                          "content-type": "multipart/form-data",
                                        },
                                      }
                                    )
                                    .then((res) => {
                                      this.setState({
                                        choice_list: JSON.parse(res.data.report),
                                      });

                                    });
  
                                //end list
                              }}
                              style={{color:primarycolor.primarycolor}}
                            >
                              ADD
                            </Button>
                          </div>
                        )}


                        </span>

  

                        {/* start of item to add*/}
                      
                       <div style={{ display: "flex", flexDirection: "column",marginLeft:20,width:"40%" }}>
                           {/**ITEM NAME */}

                          <p
                            style={{
                              display: "flex",
                              fontWeight: "bolder",
                              fontSize: 18,
                              margin:3

                            }}
                          >
                            {item.name}
                          </p>
  
                           {/**FINAL PRICE */}


                           {
                            Number(item.discount)>0?
                            <span style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                            
                                {/**Discount */}
                                <span
                                style={{
                                  display: "flex",
                                  fontSize: 16,
                                  color: 'black',
                                  margin:2,
                                  whiteSpace: 'nowrap',
                                  textDecoration: 'line-through'
                                }}
                                >
                                UGX &nbsp;
                                <CurrencyFormat
                                  value={item.price}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                              </span>

                              {/**New price */}

                              <span
                                style={{
                                  display: "flex",
                                  fontSize: 16,
                                  color: primarycolor.primarycolor,
                                  margin:3,
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                UGX &nbsp;
                                <CurrencyFormat
                                  value={item.finalprice}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                              </span>
                          
                            </span>
                            :
                            <span
                            style={{
                              display: "flex",
                              fontSize: 16,
                              color: primarycolor.primarycolor,
                              margin:3,
                              whiteSpace: 'nowrap',

                            }}
                          >
                            UGX &nbsp;
                            <CurrencyFormat
                              value={item.finalprice}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </span>
                           }


                         {/**DESCRIPTION OF ITEMS */}

                          {
                            item.description.length>30?
                            <span>

                            {
                              this.state.read_more===true &&Number(this.state.dish_id_toread_more)===Number(item.id)?
                              <span style={{display:'flex',flexDirection:'column'}}>
                                  <span
                                  style={{
                                    display: "flex",
                                    fontSize: 14,
                                    color: "grey",
                                    margin:3,
                                    }}
                                  >
                                  {item.description.toLowerCase()}

                              </span>

                              <span
                              onClick={()=>{
                              this.setState({dish_id_toread_more:''})
                              this.setState({read_more:false})

                              }} style={{ cursor: 'pointer', color: primarycolor.primarycolor,margin:3 }}>
                                
                              Show Less
                              </span>

                              </span>
                              :
                                  <span style={{display:'flex',flexDirection:'column'}}>
                                      {/**Show more button and less word  */}
                                                            
                                  <span
                                  style={{
                                    display: "flex",
                                    fontSize: 14,
                                    color: "grey",
                                    margin:3,
                                    }}
                                  >
                                  { this.getWordsInFirst50Characters(item.description)}

                                </span>


                                  <span
                                    onClick={()=>{
                                    this.setState({dish_id_toread_more:item.id})
                                    this.setState({read_more:true})

                                  }} style={{ cursor: 'pointer', color: primarycolor.primarycolor,margin:3 }}>
                                    
                                  Show More
                                  </span>

                                </span>
                                }

                            </span>

                             :
                            <p
                            style={{
                              display: "flex",
                              fontSize: 14,
                              color: "grey",
                              margin:3,

                            }}
                            >
                              {item.description.toLowerCase()}
                            </p>

                          }  
                         
                        </div>

                      </div>
  
                      <Divider />
                    </div>
                  );
                })}
              </div>
            ) : (
              <p style={{ display: "flex", justifyContent: "center" }}>
                No Dishes
              </p>
            )}
  
  

            {this.state.current_cart_list.length > 0 ? (
              <div style={{ position: 'fixed', bottom: 0, left: '2px', right: '2px', zIndex: 999, margin: '0 2px' }}>
                <div
                  onClick={() => {
                    this.setState({view_basket:true})
  
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: primarycolor.primarycolor,
                    padding: 8,
                  }}
                >
                  <span style={{ color: "white", fontSize: 18 }}>
                    {" "}
                    {this.state.current_cart_list.length} Items . UGX{" "}
                    {
                      <CurrencyFormat
                        value={this.calculateTotal()}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    }
                  </span>
  
                  <span style={{ color: "white" }}>
                    View basket &nbsp;
                    <MdShoppingCart />
                  </span>

                </div>
              </div>
            ) : null}
  
  
            {this.state.selected_dish_visible === true ? (
              <Modal
                visible={this.state.selected_dish_visible}
                title={this.state.selected_dish.name.toUpperCase()}
                onCancel={() => {
                  this.setState({ selected_dish_visible: false });
                }}
                footer={[
                  <Button
                    key="back"
                    onClick={() => {
                      this.setState({ selected_dish_visible: false });
                    }}
                  >
                    Cancel
                  </Button>,
  
                  <Button
                    type="primary"
                    onClick={() => {
                      if (
                        this.isiteminbasket(
                          this.state.selected_dish.name,
                          this.state.current_cart_list
                        ) === true
                      ) {
                        message.error("Item already added");
                      } else {
                        var intobj = {
                          key: this.state.current_cart_list.length + 1,
                          item: this.state.selected_dish.name,
                          quantity: 1,
                          price:
                            Number(this.state.selected_dish.finalprice) +
                            this.calculate_xtrasTotal()+Number(this.state.selected_dish.packaging_fees),
                          total:
                            (Number(this.state.selected_dish.finalprice) +
                              this.calculate_xtrasTotal()+Number(this.state.selected_dish.packaging_fees)) *
                            1,
                          extra_list: JSON.stringify(
                            this.state.selected_xtra_list
                          ),

                          choice_list: JSON.stringify(
                            this.state.selected_choice_list
                          ),
                          packaging_fees:Number(this.state.selected_dish.packaging_fees)*1


                        };
  
                        var new_array = [...this.state.current_cart_list, intobj];
  
                        this.setState({
                          current_cart_list: new_array,
                        });
  
                        localStorage.removeItem("current_cart_list");
                        localStorage.setItem(
                          "current_cart_list",
                          JSON.stringify({
                            items: JSON.stringify(new_array),
                            restaurant_name: this.state.restaurant_name,
                          })
                        );
  
                        message.info("Item added to basket");
                        //hide model
                        this.setState({ selected_dish_visible: false });
                        this.setState({ quantity: 0 });
                        this.setState({ selected_xtra_list: [] });
                        this.setState({ selected_choice_list: [] });

                      }
                    }}
                    icon={<PlusCircleFilled />}
                  >
                    Add item
                  </Button>,
                ]}
              >
                
                
                <div
                  style={{
                    display: "flex",
                    margin: 6,
                    flexDirection: "column",
                  }}
                >


                  {
                    this.state.choice_list.length>0?
                    <div style={{display:'flex',flexDirection:'column'}}>
                     <h6>Choose {this.state.selected_dish.no_offreechoices} free options.</h6>

                     {this.state.choice_list.map((item) => (

                      <p>
                        <Switch
                          onChange={(val) => {
                            //chekc limit on free choices
                            if (this.state.selected_choice_list.length>=this.state.selected_dish.no_offreechoices){
                              message.error("You are limted to "+String(this.state.selected_dish.no_offreechoices))
                            }else{

                            if (val === true) {
                              var intobj = {
                                key: this.state.selected_choice_list.length + 1,
                                item: item.name,
                              };
                              var new_array = [
                                ...this.state.selected_choice_list,
                                intobj,
                              ];
                              this.setState({ selected_choice_list: new_array });
                            } else {
                              this.setState({
                                selected_choice_list: [
                                  ...this.state.selected_choice_list.filter(
                                    (todo) => todo.item !== item.name
                                  ),
                                ],
                              });
                            }


                            }

                          }}
                        ></Switch>{" "}
                        {item.name} 
                      </p>
                      ))}

                       <Divider></Divider>

                    </div>
                    :
                    null
                  }

                  {
                    this.state.extra_list.length>0?
                    <div style={{display:'flex',flexDirection:'column'}}>
                    <h6>Choose extra options.</h6>

                    {this.state.extra_list.map((item) => (
                      <p style={{display:'flex',flexDirection:'row'}}>
                      <Switch
                        onChange={(val) => {
                          if (val === true) {
                            var intobj = {
                              key: this.state.selected_xtra_list.length + 1,
                              item: item.name,
                              price: item.price,
                            };
                            var new_array = [
                              ...this.state.selected_xtra_list,
                              intobj,
                            ];
                            this.setState({ selected_xtra_list: new_array });
                          } else {
                            this.setState({
                              selected_xtra_list: [
                                ...this.state.selected_xtra_list.filter(
                                  (todo) => todo.item !== item.name
                                ),
                              ],
                            });
                          }
                          
                        }}
                        style={{marginRight:2}} 

                      ></Switch>{" "}
                      {item.name} @ UGX {item.price}
                    </p>

                    ))}

                    <Divider></Divider>
                    </div>

                    :
                    null
                  }



                  {
                    Number(this.state.selected_dish.packaging_fees)>0?
                    <div>
                      <h3>Packing fees:  UGX{Number(this.state.selected_dish.packaging_fees)}</h3>
                      <Divider></Divider>
                      </div>
                    :
                    null
                  }
              

  
                  <h3>
                     Total:
                    UGX{" "}
                    {Number(this.state.selected_dish.finalprice) +this.calculate_xtrasTotal()+Number(this.state.selected_dish.packaging_fees)}
                  </h3>


                </div>
              </Modal>
            ) : null}
  

  
            {/**Continue with google */}
            <Modal
                visible={this.state.contine_withgoogle}
                title="Continue with google signin"
                onCancel={() => {
                  this.setState({ contine_withgoogle: false });
                }}
                footer={[
                  <Button
                    key="back"
                    onClick={() => {
                      this.setState({ contine_withgoogle: false });
                    }}
                  >
                    Cancel
                  </Button>,
                ]}
              >

              <SignUpForm onRefresh={this.comp_loaded} />

               
              </Modal>
    

             {/**Dish edit model */}

              {
                this.state.edit_model_visible===true?
              <Modal
                visible={this.state.edit_model_visible}
                title={this.state.editable_dish.name.toUpperCase()+" update"}
                onCancel={() => {
                  this.setState({ edit_model_visible: false });
                }}
                footer={[

                  <Button
                  type="primary"
                  key="back"
                  onClick={() => {
                   //get item to update now
 
                     const elementsIndex =
                      this.state.current_cart_list.findIndex(
                        (element) => element.item == this.state.editable_dish.name
                      );
                    let newArray = [...this.state.current_cart_list];

                    var pre_bj = newArray[elementsIndex];
                    var pre_qty = Number(pre_bj.quantity);

                    newArray[elementsIndex] = {
                      ...newArray[elementsIndex],
                      price:
                        Number(this.state.editable_dish.finalprice) +
                        this.calculate_xtrasTotal()+Number(this.state.editable_dish.packaging_fees),
                      total:
                        (Number(this.state.editable_dish.finalprice) +
                          this.calculate_xtrasTotal()+Number(this.state.editable_dish.packaging_fees)) *
                        Number(pre_qty),
                      extra_list: JSON.stringify(
                        this.state.selected_xtra_list
                      ),

                      choice_list: JSON.stringify(
                        this.state.selected_choice_list
                      ),
                      packaging_fees:Number(this.state.editable_dish.packaging_fees)*Number(pre_qty)

                    };

                    this.setState({
                      current_cart_list: newArray,
                    });

                    //update local storage
                    localStorage.removeItem("current_cart_list");
                    localStorage.setItem(
                      "current_cart_list",
                      JSON.stringify({
                        items: JSON.stringify(newArray),
                        restaurant_name: this.state.restaurant_name,
                      })
                    );                 
                 

                    message.info("Item updated");

                    //hide model
                    this.setState({ selected_dish_visible: false });
                    this.setState({ selected_xtra_list: [] });
                    this.setState({ selected_choice_list: [] });

                    this.setState({editable_dish:{}})
                    this.setState({ edit_model_visible: false });

                    }}
                  >
                  Update
                  </Button>,

                  <Button
                    key="back"
                    onClick={() => {
                      this.setState({ edit_model_visible: false });
                    }}
                  >
                    Cancel
                  </Button>,

                ]}
              >
                                
                <div
                  style={{
                    display: "flex",
                    margin: 6,
                    flexDirection: "column",
                  }}
                >

                  {
                    this.state.choice_list.length>0?
                    <div>
                     <h6 style={{color:primarycolor.primarycolor}} >Choose ({this.state.editable_dish.no_offreechoices}) free options.</h6>

                    {this.state.choice_list.map((item) => (
                      <p>
                        <Switch
                          onChange={(val) => {
                      
                            if (val === true) {

                              //chekc limit on free choices
                              if (this.state.selected_choice_list.length>=this.state.selected_dish.no_offreechoices){
                                message.error("You are limted to "+String(this.state.selected_dish.no_offreechoices))
                              }else if (this.checKIfItem_inchoicelist(item.name,this.state.selected_choice_list)===true){
                                message.error("Already selected")

                              }else{

                                var intobj = {
                                  key: this.state.selected_choice_list.length + 1,
                                  item: item.name,
                                };
                                var new_array = [
                                  ...this.state.selected_choice_list,
                                  intobj,
                                ];
                                this.setState({ selected_choice_list: new_array });

                              }



                            } else {

                            if (this.checKIfItem_inchoicelist(item.name,this.state.selected_choice_list)===true){
                              this.setState({
                                selected_choice_list: [
                                  ...this.state.selected_choice_list.filter(
                                    (todo) => todo.item !== item.name
                                  ),
                                ],
                              });

                            }
                            
                            }

                          }}

                        //chekc if check
                        checked={this.checKIfItem_inchoicelist(item.name,this.state.selected_choice_list)===true?true:false}
                        checkedChildren="on"
                        unCheckedChildren="off"

                        />
                        &nbsp;{item.name} 
                      </p>
                    ))}  

                    <Divider></Divider>


                    </div>
                    :
                    null
                  }



                  {
                                     
                    this.state.extra_list.length>0?
                    <div>
                            <h6 style={{color:primarycolor.primarycolor}}>Choose extra options.</h6>
                              
                              {this.state.extra_list.map((item) => (
                                <p style={{display:'flex',flexDirection:'row'}}>
                                  <Switch
                                    onChange={(val) => {
                                      if (val === true) {

                                        if (this.checKIfItem_inextralist(item.name,this.state.selected_xtra_list)===true){
                                          message.error("Already selected")

                                        }else{
                                          var intobj = {
                                            key: this.state.selected_xtra_list.length + 1,
                                            item: item.name,
                                            price: item.price,
                                          };
                                          var new_array = [
                                            ...this.state.selected_xtra_list,
                                            intobj,
                                          ];
                                          this.setState({ selected_xtra_list: new_array });

                                        }


                                      } else {

                                        if (this.checKIfItem_inextralist(item.name,this.state.selected_xtra_list)===true){
                                          this.setState({
                                            selected_xtra_list: [
                                              ...this.state.selected_xtra_list.filter(
                                                (todo) => todo.item !== item.name
                                              ),
                                            ],
                                          });

                                        }

                                      }
                                      
                                    }}
                                      style={{marginRight:2}} 

                                  //chekc if check
                                  checked={this.checKIfItem_inextralist(item.name,this.state.selected_xtra_list)===true?true:false}
                                  checkedChildren="on"
                                  unCheckedChildren="off"

                                  />
                                &nbsp;  {item.name} @ UGX {item.price}
                                </p>
                              ))}

                            </div>
                            :null
                    
                  }

            
                </div>

              </Modal>
              :
              null
              }


              {/**Check out model */}
              <Modal
                visible={this.state.view_basket}
                title="ORDER SUMMARY"
                onCancel={() => {
                  this.setState({ view_basket: false });
                }}
                footer={[
                  <Button
                    key="back"
                    onClick={() => {
                      this.setState({ view_basket: false });
                    }}
                  >
                    Cancel
                  </Button>,
                ]}
                width={"100%"}

              >
         
             {
              this.state.current_cart_list.map((item)=>{
                return(
                  <div>
                     <div style={{display:'flex',justifyContent:'flex-start'}}>
                    
                    {/**item display */}
                     <div style={{display:'flex',flexDirection:'column',width:"40%",margin:3}}>
                      
                      <span style={{}}>
                      {this.capitalizeFirstLetter(item.item)}
                      </span>

                      {
                        JSON.parse(item.choice_list).length>0?
                        <span style={{display:'flex',flexDirection:'column'}}>
                        <span>Choices:</span>

                        <span style={{justifyContent:'flex-start',display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                        {
                          JSON.parse(item.choice_list).map((choice)=>{
                            return <span style={{color:'grey'}}>{this.capitalizeFirstLetter(choice.item)} </span>
                          })  
                        }
                        </span>

                        </span>
                        :
                        null
                      }

                      
                      {
                        JSON.parse(item.extra_list).length>0?
                        <span style={{display:'flex',flexDirection:'column'}}>
                         <span>Extras:</span>
                        {
                          JSON.parse(item.extra_list).map((extra)=>{
                            return <span style={{color:'grey'}}>{this.capitalizeFirstLetter(extra.item) } - UGX{extra.price} </span>
                          })  
                        }
                      </span>
                      :
                      null}


                      {
                        Number(item.packaging_fees)>0?
                        <span style={{display:'flex',flexDirection:'column'}}>
                         <span>Packaging Fees - UGX {item.packaging_fees}</span>
                        </span>
                      :
                      null
                      }

                      {/**Button click her  */}

                      <span 
                      onClick={()=>{
                        //SET selected choices and extras
                        this.setState({selected_choice_list:JSON.parse(item.choice_list)})
                        this.setState({selected_xtra_list:JSON.parse(item.extra_list)})


                        //get the editable object
                        const elementsIndex =
                        this.state.menu_dishes.findIndex(
                          (element) => element.name == item.item
                        );

                        //current editable dish  
                        var pre_bj = this.state.menu_dishes[elementsIndex];
                        
                        this.setState({editable_dish:pre_bj})


                        let form_data = new FormData();
                        form_data.append("dish_id", pre_bj.id);
                        axios
                          .post(
                            serverconfig.backendserverurl +
                              `/customqueries/get_dish_extras`,
                            form_data,
                            {
                              headers: {
                                "content-type": "multipart/form-data",
                              },
                            }
                          )
                          .then((res) => {

                            this.setState({
                              extra_list: JSON.parse(res.data.report),
                            });

                            if (JSON.parse(res.data.report).length>0){
                              this.setState({edit_model_visible:true})

                            }



                          });


                          let form_data_1 = new FormData();
                          form_data_1.append("dish_id", pre_bj.id);
                          axios
                            .post(
                              serverconfig.backendserverurl +
                                `/customqueries/get_dish_choices`,
                                form_data_1,
                              {
                                headers: {
                                  "content-type": "multipart/form-data",
                                },
                              }
                            )
                            .then((res) => {
                              this.setState({
                                choice_list: JSON.parse(res.data.report),
                              });

                              if (JSON.parse(res.data.report).length>0){
                                this.setState({edit_model_visible:true})

                              }


                            });


                      }}
                      
                      style={{color:primarycolor.primarycolor,fontWeight:'bold',marginLeft:0}}>
                        More
                      </span>
                        

                    </div>


                      {/**Add minu buttons */}
                     <div
                       
                       style={{
                         display: "flex",
                         justifyContent: "space-between",
                         background: 'white',
                         padding: 8,
                         height:30,
                         alignItems:'center',
                         border: '1px solid lightgreen',
                         margin:5,
                         width:"20%"
                       }}
                       >
                       <span 
                       className="no-select"
                       style={{ color:primarycolor.primarycolor,margin:4,fontSize: 18 }}
                       
                       onClick={() => {


                        const elementsIndex =
                          this.state.current_cart_list.findIndex(
                            (element) => element.item == item.item
                          );
                        let newArray = [...this.state.current_cart_list];
                        console.log(elementsIndex);

                        var pre_bj = newArray[elementsIndex];
                        var pre_qty = Number(pre_bj.quantity);
                        var pre_price = Number(pre_bj.price);
                        var pre_key = pre_bj.key;

                        newArray[elementsIndex] = {
                          ...newArray[elementsIndex],
                          quantity: pre_qty + 1,
                          total: (pre_qty + 1) * pre_price,
                        };

                        this.setState({
                          current_cart_list: newArray,
                        });

                        //update local storage
                        localStorage.removeItem("current_cart_list");
                        localStorage.setItem(
                          "current_cart_list",
                          JSON.stringify({
                            items: JSON.stringify(newArray),
                            restaurant_name: this.state.restaurant_name,
                          })
                        );
                      }}
                       
                       >
                         +
                       </span>

                       <span style={{ color: primarycolor.primarycolor, fontSize: 18,margin:4 }}>
                        {item.quantity}
                       </span>


                       <span 
                       className="no-select"
                       style={{ color:primarycolor.primarycolor, fontSize: 18,margin:4 }}
                          onClick={() => {
                            const elementsIndex =
                              this.state.current_cart_list.findIndex(
                                (element) => element.item == item.item
                              );
                            let newArray = [...this.state.current_cart_list];
  
                            var pre_bj = newArray[elementsIndex];
                            var pre_qty = Number(pre_bj.quantity);
                            var pre_price = Number(pre_bj.price);
                            var pre_key = pre_bj.key;
  
                            if (pre_qty <= 1) {

                              this.setState({
                                current_cart_list: [
                                  ...this.state.current_cart_list.filter(
                                    (todo) => todo.key !== pre_key
                                  ),
                                ],
                              });

                              var nnewArray=[
                                ...this.state.current_cart_list.filter(
                                  (todo) => todo.key !== pre_key
                                ),
                              ]


                              //update local storage
                              localStorage.removeItem("current_cart_list");
                              localStorage.setItem(
                                "current_cart_list",
                                JSON.stringify({
                                  items: JSON.stringify(nnewArray),
                                  restaurant_name: this.state.restaurant_name,
                                })
                              );


                            } else {
                              newArray[elementsIndex] = {
                                ...newArray[elementsIndex],
                                quantity: pre_qty - 1,
                                total: (pre_qty - 1) * pre_price,
                              };
  
                              this.setState({
                                current_cart_list: newArray,
                              });
  
                              //update local storage
                              localStorage.removeItem("current_cart_list");
                              localStorage.setItem(
                                "current_cart_list",
                                JSON.stringify({
                                  items: JSON.stringify(newArray),
                                  restaurant_name: this.state.restaurant_name,
                                })
                              );
                            }
                          }}
   
                       >
                         -
                       </span>

                       </div>

                    {/**price display */}

                       <div style={{display:'flex',flexDirection:'column',width:"30%",margin:3}}>
                            <p>
                            UGX <CurrencyFormat value={item.total} displayType={'text'} thousandSeparator={true}/>
          
                            </p>
                       </div>
 
                  </div>
                  <Divider></Divider>
                    </div>
                )
              })
            }
  
          <Divider></Divider>

          <Form>
      
          <FormItem  
            label='DELIVERY AREA  '
            name="deliveryarea"
            rules={[
              {
                required: true,
                message: 'Please select area',
              },
            ]}
          
          
          >
          <Select 
          placeholder="" 
          value={this.state.area} 
          onChange={(val)=>{
            console.log(val)
            this.setState({area:val})
            this.setState({delivery_price:0})

            //get delivery fees
             this.state.hub_zones.map((zone)=>{
              var zone_list=JSON.parse(zone.zone_list)
              var price=Number(zone.price)
              console.log(zone_list)
              if (this.isareaAlreadyExists(val,zone_list)){
                console.log("Exists")
                this.setState({delivery_price:price})

              }else{

                console.log("No in zone")
              }

            })   
          
          }} 
          showSearch
          optionFilterProp="children"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onSearch={this.onSearch}    
          style={{ marginBottom: 16 }} // Add margin bottom to Form.Item

          >
             {this.state.areas.map(
              (cat)=>(
                <Option value={cat.name}>{this.capitalizeSentence(cat.name)}</Option>
              ))}
          </Select>
         </FormItem>
  
         <Divider></Divider>
  
          <FormItem label='PHONE  '
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: 'Please input phone',
                    },
                  ]}
                  style={{ marginBottom: 16 }} // Add margin bottom to Form.Item

          >
            <Input 
            name="telephone_contact" 
            placeholder=""
            value={this.state.telephone_contact} 
            onChange={(val)=>{this.setState({telephone_contact:val.target.value})}}
            maxLength={10}
            type="number"
            />
          </FormItem>

          </Form>


          <Divider></Divider>

          <FormItem  label={'Notes '}>
            <Input 
            name="note" 
            placeholder=""
            value={this.state.notes} 
            onChange={(val)=>{this.setState({notes:val.target.value})}}
            />
          </FormItem>

    
  
          <Divider></Divider>
  
          <h6>BILL</h6>
          <div style={{display:'flex',justifyContent:'space-between'}}>
            <p>Item Total</p>  
            <p>
            UGX <CurrencyFormat value={this.calculateTotal()} displayType={'text'} thousandSeparator={true}/>
            </p>      
  
          </div>
  

          <div style={{display:'flex',justifyContent:'space-between'}}>
            <p>Delivery Fee</p>  
            <p>
            UGX <CurrencyFormat value={this.state.delivery_price} displayType={'text'} thousandSeparator={true}/>
            </p>      
  
          </div>


          <Divider></Divider>
          <h3>Total: UGX  <CurrencyFormat value={Number(this.calculateTotal())+Number(this.state.delivery_price)} displayType={'text'} thousandSeparator={true}/></h3>    
          <Form>
          <FormItem  label={"Payment Mode "}>
              <Select 
              placeholder="" 
              value={this.state.mode_of_payment} 
              onChange={(val)=>{
                console.log(val)
                this.setState({mode_of_payment:val})
              }} 
              showSearch
              optionFilterProp="children"
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onSearch={this.onSearch}    
              style={{ marginBottom: 16 }} // Add margin bottom to Form.Item

              >
            <Option value={'Cash'}>Cash</Option>
            <Option value={'MobileMoney'}>Mobile Money</Option>
          </Select>
          </FormItem>  


          </Form>

          {
            this.state.restaurant_obj.mtn_momopay_code!=null && this.state.restaurant_obj.mtn_momopay_code!='' && this.state.restaurant_obj.mtn_momopay_code!='null' && this.state.mode_of_payment==="MobileMoney"?
            <div style={{display:'flex',justifyContent:'space-between'}}>
            <p>MTN MOMOPAY</p>  
            <p>
              {this.state.restaurant_obj.mtn_momopay_code}
            
            </p>      
  
            </div>

            :
            null
          }        


          {
            this.state.restaurant_obj.airtelpay_code!=null && this.state.restaurant_obj.airtelpay_code!='' && this.state.restaurant_obj.airtelpay_code!='null' && this.state.mode_of_payment==="MobileMoney"?
            <div style={{display:'flex',justifyContent:'space-between'}}>
            <p>AIRTEL PAY</p>  
            <p>
              {this.state.restaurant_obj.airtelpay_code}
            </p>      
  
            </div>
            :
            null
          }
  
  
            {this.state.current_cart_list.length > 0 ? (
              <Affix>
                <div
                onClick={()=>{
                  var g_user=''
  
                  if (localStorage.getItem("g_user")!="" &&  localStorage.getItem("g_user")!=null){
                      g_user=localStorage.getItem("g_user")
                  
                      let form_data = new FormData();
                      form_data.append("client__id", g_user);
                      axios
                        .post(
                          serverconfig.backendserverurl + `/customqueries/get_google_userprofile`,
                          form_data,
                          {
                            headers: {
                              "content-type": "multipart/form-data",
                            },
                          }
                        )
                        .then((res) => {
                              var user_obj=res.data
                              //now create the order now
                                if (this .state.area===""){
                                  message.error("Please enter area")
                                }else if (this .state.telephone_contact===""){
                                  message.error("Please enter contact")
                                }else if (this.state.current_cart_list.length<=0){
                                  message.error("Basket cant be empty")
                                }else{
                            
                                this.setState({datarequested:true})
                                let form_data = new FormData();
                                form_data.append('client_name', user_obj.full_name);
                                form_data.append('client_email', user_obj.email);
  
                                form_data.append('client_phone', this.state.telephone_contact);
                                form_data.append('restaurant',this.state.restaurant_obj.id);
                                form_data.append('area',this.state.area);
                                form_data.append('delivery_price',this.state.delivery_price);
                                form_data.append('date',moment().format(dateFormat).toString());
                                form_data.append('order_total',this.calculateTotal());

                                form_data.append('placing_time', moment().format(t_time).toString());
                                form_data.append('mode_of_payment',this.state.mode_of_payment);
                                form_data.append('order_type',"online");
                                form_data.append('selected_rider',"");
                                form_data.append('item_details',JSON.stringify(this.state.current_cart_list) );
                                form_data.append('notes',this.state.notes);

                                axios.post(serverconfig.backendserverurl+'/customqueries/createonlineorder', form_data, {
                                  client_nameers: {
                                    'content-type': 'multipart/form-data'
                                  }
                                })
                              .then(res => {
                                this.setState({status_message:"Order created successful. Order number is "+String(res.data.ordernumber)})
                               
                                localStorage.setItem("current_order_number",
                                JSON.stringify(
                                  {
                                    "order_number":String(res.data.ordernumber),
                                    "restaurant":String(this.state.restaurant_obj.name)
                                  })

                                );

                                this.setState({datarequested:false})
                                this.setState({datasubmittedsuccessfully:true})
  
                                  //reset baseket
                                localStorage.removeItem("current_cart_list");
                                this.setState({view_basket:false})
    
                                })
                              .catch(error => console.log(error))
                            }
  
                        });
  
                  }else{
                    this.setState({contine_withgoogle:true})
  
                  }
  
                }}
                
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    background: primarycolor.primarycolor,
                    padding: 8,
                  }}
                >
                  <span style={{ color: "white", fontSize: 18 }}>
                  PLACE ORDER
                  </span>
                    
                </div>
              </Affix>
            ) : null}
   

           </Modal>
  
          </div>
        );

      }

    }
  }
}
RestaurantsMenuDetails.contextType = MyContext;

export default RestaurantsMenuDetails;
