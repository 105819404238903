import React, { useRef, useEffect, useState } from 'react';
import './SlideBar.css'; // Import CSS file for styling
import { Link } from "react-router-dom";
import { Button } from 'antd';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';


// Import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const SlideBar = ({ promotions }) => {

  const swiperRef = useRef(null);

  const scrollLeft = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const scrollRight = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };


  useEffect(() => {
   
  }, []);

  return (
        <div style={{width:'100%',height:210,justifyContent:'center',alignSelf:'center'}}>
        <Swiper
          spaceBetween={10}
          slidesPerView={1}        
          onSlideChange={() => console.log('slide change')}

          onSwiper={(swiper) => (swiperRef.current = swiper)}
          navigation={{
            prevEl: '.scroll-button-left',
            nextEl: '.scroll-button-right'
          }}
          breakpoints={{
            300: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 5,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 5,
            },
          }}
          modules={[Navigation]}


          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}

        >
            <button className="scroll-button scroll-button-left" onClick={scrollLeft}>{'<'}</button>

            {promotions.map((promotion, index) => (
               <SwiperSlide 
               key={index} style={{display:'flex',flexDirection:'column',justifyContent:'flex-end',height:200,margin:2}}>
                 <div  key={index} style={{display:'flex',flexDirection:'column',justifyContent:'flex-end',height:200,margin:2}}>
              <span 
                style={{
                  height:"100%",
                  display:'flex',
                  flexDirection:'column',
                  backgroundImage: `url(${promotion.image})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  width: "100%",
                  justifyContent:'flex-end',
                  alignItems:'flex-end',
                  borderRadius: '5px',
                }}>
                <span style={{color:'white', backgroundColor:'black', margin:2, padding:3}}>
                  {promotion.head}
                </span>
              </span>
              <div style={{display:'flex',flexDirection:'column',width:'100%',justifyContent:'center',margin:5,alignItems:'center'}}>
                <h6>{promotion.restaurant}</h6>
                <Link to={`/${promotion.online_order_link}`}>
                  <Button type='primary'>View Promo</Button>
                </Link>
              </div>   
          </div>

          </SwiperSlide>
           
        ))}

        <button className="scroll-button scroll-button-right" onClick={scrollRight}>{'>'}</button>

    </Swiper>



        </div>
  );
};

export default SlideBar;
